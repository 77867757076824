import { ApiHelper } from "./helper/newuiApiHelper";
// import axios from "axios";
const apiHelper = new ApiHelper();
export class DataVerification {
  constructor() {}
  getStatusForClient(orgName, brand, modelName, channelType) {
    const uri = `health-monitor-module/historytable/statusForClient?organization=${orgName}&brand=${brand}&modelName=${modelName}&channelType=${channelType}`;
    return apiHelper.get(uri);
  }
  addPocs(data) {
    const uri = `health-monitor-module/poctable/addPoc`;
    return apiHelper.post(uri, data);
  }
  getYearsForDataVerification(orgNumber) {
    const uri = `health-monitor-module/v1/year?orgNumber=${orgNumber}`;
    return apiHelper.get(uri);
  }
  getHistoryDataByType(data) {
    const uri = `health-monitor-module/historytable/getHistory?orgName=${data.orgName}&brand=${data.brand}&modelName=${data.modelName}&channelType=${data.channelType}&type=${data.type}`;
    return apiHelper.get(uri);
  }
  getHistoryDataAi() {
    const uri = `health-monitor-module/historytable/getHistoryAi`;
    return apiHelper.get(uri);
  }
  getHistoryDataClient(orgName) {
    const uri = `health-monitor-module/historytable/getHistoryClient?organization=${orgName}`;
    return apiHelper.get(uri);
  }
  getHistoryStep0() {
    const uri = `health-monitor-module/historytable/getStep0Data`;
    return apiHelper.get(uri);
  }
  // getHeaders() {
  //     var token = sessionStorage.getItem("Token");
  //     return {
  //       Authorization: token,
  //     };
  // }
  getDataTable(data) {
    const uri = `health-monitor-module/dataverification/v1/getClientData?orgNumber=${data.orgNumber}&years=${data.years}&months=${data.months}&quarters=${data.quarters}&type=${data.type}&requiredMetrics=${data.metrics}&page=${data.page}&size=${data.size}&actualBrandName=${data.actualBrandName}&model=${data.modelName}`;
    return apiHelper.get(uri);
  }
  getChannelTypeValues() {
    const uri = `health-monitor-module/dataverification/getChannelType`;
    return apiHelper.get(uri);
  }
  getChannelTypes(data) {
    const uri = `health-monitor-module/poctable/getChannelTypes?orgname=${data.orgName}&brandname=${data.brandName}&modelname=${data.modelName}&emailId=${data.emailId}&type=${data.type}`;
    return apiHelper.get(uri);
  }
  getChannelTypesAI(data) {
    const uri = `health-monitor-module/poctable/getChannelTypesAi?orgname=${data.orgName}&brandname=${data.brandName}&modelname=${data.modelName}&emailId=${data.emailId}`;
    return apiHelper.get(uri);
  }
  getStatusForAI(orgName, brand, modelName, channelType) {
    const uri = `health-monitor-module/historytable/statusAi?organization=${orgName}&brand=${brand}&modelName=${modelName}&channelType=${channelType}`;
    return apiHelper.get(uri);
  }
  getGeneralStatus(orgName, brand, modelName, channelType) {
    const uri = `health-monitor-module/historytable/getConstantStatus?organization=${orgName}&brand=${brand}&modelname=${modelName}&channelType=${channelType}`;
    return apiHelper.get(uri);
  }
  aprrovedByProduct(data) {
    const uri = `health-monitor-module/historytable/approveByProduct?emailid=${data.emailId}&organization=${data.organization}&brand=${data.brand}&modelname=${data.modelName}&channeltype=${data.channelType}&comment=${data.comment}`;
    return apiHelper.post(uri);
  }
  revokedByProduct(data) {
    const uri = `health-monitor-module/historytable/revokeByProduct?emailid=${data.emailId}&organization=${data.organization}&brand=${data.brand}&modelname=${data.modelName}&channeltype=${data.channelType}&comment=${data.comment}`;
    return apiHelper.post(uri);
  }
  revokedByAi(data) {
    const uri = `health-monitor-module/historytable/revokeByAi?emailid=${data.emailId}&organization=${data.organization}&brand=${data.brand}&modelname=${data.modelName}&inputDataType=${data.inputDataType}&channelCategory=${data.channelCategory}&channeltype=${data.channelType}&channel=${data.channel}&audience=${data.audience}&duration=${data.duration}&comment=${data.comment}`;
    return apiHelper.post(uri);
  }
  revokedByClient(data) {
    const uri = `health-monitor-module/historytable/revokeByClient?emailid=${data.emailId}&organization=${data.organization}&brand=${data.brand}&modelname=${data.modelName}&inputDataType=${data.inputDataType}&channelCategory=${data.channelCategory}&channeltype=${data.channelType}&channel=${data.channel}&audience=${data.audience}&duration=${data.duration}&comment=${data.comment}`;
    return apiHelper.post(uri);
  }
  aprrovedByAi(data) {
    const uri = `health-monitor-module/historytable/approveByAi?emailid=${data.emailId}&organization=${data.organization}&brand=${data.brand}&modelname=${data.modelName}&channeltype=${data.channelType}&comment=${data.comment}`;
    return apiHelper.post(uri);
  }
  aprrovedByClient(data) {
    const uri = `health-monitor-module/historytable/approveByClient?emailid=${data.emailId}&organization=${data.organization}&brand=${data.brand}&modelname=${data.modelName}&channeltype=${data.channelType}&comment=${data.comment}`;
    return apiHelper.post(uri);
  }
  getOrgByMailId(emailId, type) {
    const uri = `health-monitor-module/poctable/getOrgNames?emailId=${emailId}&type=${type}`;
    return apiHelper.get(uri);
  }
  getBrandsFromPocTable(orgId, emailId, type) {
    const uri = `health-monitor-module/poctable/getBrandNames?orgId=${orgId}&emailId=${emailId}&type=${type}`;
    return apiHelper.get(uri);
  }
  getAllOrg() {
    const uri = `user-module/getAllorg`;
    return apiHelper.get(uri);
    //     return axios
    // .get('http://34.226.103.91:8080/api/' + uri, {
    //   headers: this.getHeaders(),
    //   withCredentials: false,
    // })
    // .then(this.checkResponse)
    // .catch(this.handleError);
  }
  getBrandDetails(brandName, subscription) {
    const uri = `user-module/v1/getBrandDetailsByOrg?brandName=${brandName}&subscription=${subscription}`;
    return apiHelper.get(uri);
    //     return axios
    // .get('http://34.226.103.91:8080/api/' + uri, {
    //   headers: this.getHeaders(),
    //   withCredentials: false,
    // })
    // .then(this.checkResponse)
    // .catch(this.handleError);
  }
  addModel(brandName, orgId, modelName) {
    const uri = `user-module/v1/addModel?brandName=${brandName}&subscription_id=${orgId}&modelName=${modelName}`;
    return apiHelper.post(uri);
    //     return axios
    // .post('http://34.226.103.91:8080/api/' + uri, {
    //   headers: this.getHeaders(),
    //   withCredentials: false,
    // })
    // .then(this.checkResponse)
    // .catch(this.handleError);
  }
  getClientPocEmail(emailId) {
    const uri = `user-module/v1/created-by?email=${emailId}&page=1&size=1000`;
    return apiHelper.get(uri);
    //     return axios
    // .get('http://34.226.103.91:8080/api/' + uri, {
    //   headers: this.getHeaders(),
    //   withCredentials: false,
    // })
    // .then(this.checkResponse)
    // .catch(this.handleError);
  }
  getEmailByOrgName(orgId) {
    const uri = `user-module/subscriber/v1/get-by-org-id?orgId=${orgId}`;
    return apiHelper.get(uri);
    // return axios
    // .get('http://34.226.103.91:8080/api/' + uri, {
    //   headers: this.getHeaders(),
    //   withCredentials: false,
    // })
    // .then(this.checkResponse)
    // .catch(this.handleError);
  }
}
