<template>
    <div>
      <div class="page-wrapper chiller-theme" style="margin-top: 71px">
        <main class="page-content">
          <Toast
            v-if="toastData.show"
            :type="toastData.type"
            :message="toastData.message"
            :show="toastData.show"
            :time="10000"
            @clearToast="clearToast"
          />
          <div v-if="subId === 'aoaukkdwvzjlszefntfq' || subId === 'mqlrludacmqjvonlifei' || subId === 'bmrfjilcasneiwxynugg'" class="container-fluid">
            <demo-breadcrumb />
          </div>
          <div v-else>
            <ul
              class="breadcrumb ProximaNovaRegular"
              style="margin-left: 52px; margin-top: 12px"
            >
              <li class="ProximaNovaBold">Upload Data</li>
            </ul>
          </div>
  
          <div class="d-flex justify-content-between align-items-center">
            <go-back />
            <div class="d-flex">
              <generic-dropdown
                type="brands"
                :model="false"
                :zIndex="30"
                labelText="Brand"
              />
              <go-to-page />
            </div>
          </div>
  
          <div class="w-100 py-4 mb-5">
            <div
              class="container-fluid"
              style="margin-top: 36px; padding-left: 52px"
            >
              <div
                class="col-md-12 col-lg-12 product-data-section d-flex justify-center"
              >
                <div class="col-md-8 col-sm-12 col-lg-8 p-0">
                  <div class="card w-100">
                    <div class="row product-data-section m-0 p-0">
                      <div class="col-md-4 col-lg-4 content-center">
                        <div class="w-100">
                          <img
                            src="../../assets/Icons/group-21.png"
                            alt="KPI"
                            class="img img-responsive"
                          />
                        </div>
                      </div>
                      <div class="col-md-8 col-lg-8">
                        <div class="row m-0 p-0">
                          <div class="w-100 d-flex pt-5">
                            <span
                              class="ProximaNovaBold"
                              style="font-size: x-large"
                              >Upload data</span
                            >
                          </div>
                          <div class="w-100 d-flex pb-5">
                            <span class="Use-product-data-to ProximaNovaRegular">
                              Use product data to create media ROI at product
                              level
                            </span>
                          </div>
                          <div class="w-100 d-flex pb-5">
                            <b-form-input
                              type="email"
                              class="form-control input-control c-form-input m-0 w-100"
                              aria-describedby="emailHelp"
                              placeholder="Data stream name"
                              name="example-input-1"
                              v-model="dataStreamName"
                            ></b-form-input>
                          </div>
                          <div class="w-100 d-flex pb-5">
                            <w-dropdown
                              :labelText="'Organization * '"
                              :options="OrganizationList"
                              :selectedOption="selectedOrganization"
                              @input="getSelectedOrganization($event)"
                            >
                            </w-dropdown>
                          </div>
                          <div class="w-100 d-flex pb-5">
                            <w-dropdown
                              :labelText="'Brand * '"
                              :options="brandList"
                              :selectedOption="selectedBrand"
                              @input="getSelectedBrand($event)"
                            >
                            </w-dropdown>
                          </div>
                          <div class="w-100 d-flex pb-5">
                            <w-dropdown
                              :labelText="'Channel Category *'"
                              :options="ChannelCategoryList"
                              :selectedOption="selectedChannelCategory"
                              @input="getSelectedChannelCategory($event)"
                            >
                            </w-dropdown>
                          </div>
                          <div class="w-100 d-flex pb-5">
                            <w-dropdown
                              :labelText="'Channel Type * '"
                              :options="ChannelTypeList"
                              :selectedOption="selectedChannelType"
                              @input="getSelectedChannelType($event)"
                            >
                            </w-dropdown>
                          </div>
  
                          <div class="w-100 d-flex pb-5">
                            <w-dropdown
                              :labelText="'Model Name *'"
                              :options="allModelsList"
                              :selectedOption="selectedModel"
                              @input="getSelectedModel($event)"
                            >
                            </w-dropdown>
                          </div>
                          <div class="mb-5">
                            <center>
                              <a
                                style="cursor: pointer"
                                class="download-channel-data"
                                @click="downloadTemp"
                              >
                                {{ `Download ${showSelectedTypeText}` }}
                              </a>
                            </center>
                          </div>
  
                          <div
                            class="w-100 px-0 pb-5 d-flex justify-center input-types"
                          >
                            <input
                              type="file"
                              id="actual-btn"
                              ref="fileInput"
                              accept=".csv"
                              @change="onFileChange"
                              hidden
                            />
                            <label class="file-label" for="actual-btn" v-if="seen"
                              >Choose File</label
                            >
                            <span
                              id="file-chosen"
                              ref="file-chosen"
                              class="d-flex"
                              style="justify-content: space-between"
                              >{{ file }}
                              <label
                                class="remove-label mr-5"
                                v-if="!seen"
                                @click="clearFileUploader"
                                >Remove</label
                              >
                            </span>
                          </div>
                          <div
                            class="w-100 d-flex pb-5"
                            style="
                              font-size: small;
                              color: #ff5a5a;
                              justify-content: center;
                            "
                            v-if="errorMessage"
                          >
                            {{ errorMessage }}
                          </div>
                          <div
                            class="w-100 d-flex pb-5"
                            style="justify-content: flex-end"
                          >
                            <div>
                              <w-button
                                :buttonLoader="uploadButtonLoader"
                                :buttonText="uploadButtonText"
                                :isDisable="disableConnectors"
                                :class="{ resetPasswordLink: disableConnectors }"
                                @buttonClicked="createPlan"
                              >
                              </w-button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <w-button style="width: 256px; margin-right: 50px" :buttonLoader="'normal'" :buttonText="'Continue'"
            @buttonClicked="handleContinue" class="mt-5" />
                  <demo-navigation-button/>
                </div>
              </div>
            </div>
          </div>
          <solution-modal ref="solutionModal" @progressDone="doneProgress" />
        </main>
      </div>
    </div>
  </template>
  <script>
  const Button = () => import("@/components/Profile/Button.vue");
  const SolutionModal = () => import("@/components/Modal/SolutionModal.vue");
  const Dropdown = () => import("@/widgets/Dropdown.vue");
  import { DataConnection } from "../../services/DataConnectionService";
  import { DataVerification } from "../../services/DataVerificationServices.js";
  const Toast = () => import("@/components/Toast/Toast.vue");
  const dataConnection = new DataConnection();
  const dataVerification = new DataVerification();
  
  // import DemoMixins from "../DEMO/MARS/index";
  
  export default {
    props: [],
    // mixins: [DemoMixins],
    components: {
      "w-button": Button,
      "solution-modal": SolutionModal,
      "w-dropdown": Dropdown,
      Toast,
    },
    data() {
      return {
        subId: null,
        categoriesList: [
          { id: "Digital Media", text: "Digital Media" },
          { id: "Web Analytics", text: "Web Analytics" },
          { id: "Social Insights", text: "Social Insights" },
        ],
        uploadButtonText: "Upload",
        uploadButtonLoader: "normal",
        isCategoryNameOptional: true,
        brandList: [],
        ChannelCategoryList: [
          { id: "1", text: "Master Data Format" },
          { id: "2", text: "Paid Digital Data" },
          { id: "3", text: "Non Paid Data" },
          { id: "4", text: "KPI Data" },
          { id: "5", text: "Omni Channel USA" },
          { id: "6", text: "Omni Channel India" },
          { id: "7", text: "Brand Data" },
        ],
        // ChannelTypeList: [
        //   { id: "1", text: "Paid Digital Data" },
        //   { id: "2", text: "Non Paid Data" },
        //   { id: "3", text: "KPI Data" },
        // ],
        OrganizationList: [
          { id: "1", text: `${sessionStorage.getItem("organization")}` },
        ],
        selectedBrand: {},
        selectedChannelCategory: {},
        selectedChannelType: {},
        selectedOrganization: {
          id: "1",
          text: `${sessionStorage.getItem("organization")}`,
        },
        selectedCategory: {},
        downloadTempText: "Download Template",
        downloadButtonLoader: "normal",
        file: "No file chosen",
        selectedChannel: {},
        seen: true,
        uplodaData: "",
        errorMessage: "",
        dataStreamName: "",
        allModelsList: [],
        selectedModel: {},
        toastData: {
          show: false,
          type: "",
          message: "",
        },
      };
    },
    computed: {
      showChannelOptionalMark() {
        if (this.isCategoryNameOptional === true) {
          return "Channel";
        } else {
          return "Channel * ";
        }
      },
      disableConnectors() {
        console.log(this.isCategoryNameOptional);
        if (
          this.checkObje(this.selectedBrand) !== 0 &&
          this.checkObje(this.selectedChannelCategory) !== 0 &&
          this.checkObje(this.selectedChannelType) !== 0 &&
          // this.checkObje(this.selectedModel)!==0 &&
          this.dataStreamName !== "" &&
          this.uplodaData !== "" &&
          this.uploadButtonLoader === "normal"
        ) {
          return false;
        }
        return true;
      },
      disableDownload() {
        if (
          this.checkObje(this.selectedChannelCategory) !== 0 &&
          this.checkObje(this.selectedChannelType) !== 0
        ) {
          return false;
        }
        return true;
      },
      checkObje() {
        return (data) => {
          return Object.keys(data).length;
        };
      },
      // channelList() {
      //   if (this.selectedCategory.text === "Digital Media") {
      //     return [
      //       { id: "Google Ads", text: "Google Ads" },
      //       { id: "Facebook Ads", text: "Facebook Ads" },
      //       { id: "LinkedIn Ads", text: "LinkedIn Ads" },
      //       { id: "Twitter Ads", text: "Twitter Ads" },
      //       { id: "Display and Video 360", text: "Display and Video 360" },
      //       { id: "Microsoft Ads", text: "Microsoft Ads" },
      //       { id: "Pinterest", text: "Pinterest" },
      //       { id: "Snapchat", text: "Snapchat" },
      //     ];
      //   } else if (this.selectedCategory.text === "Web Analytics") {
      //     return [
      //       { id: "Adobe Analytics", text: "Adobe Analytics" },
      //       { id: "Google Analytics", text: "Google Analytics" },
      //       { id: "Google Trends", text: "Google Trends" },
      //     ];
      //   } else if (this.selectedCategory.text === "Social Insights") {
      //     return [
      //       {
      //         id: "Google Ads Keyword Planner",
      //         text: "Google Ads Keyword Planner",
      //       },
      //       { id: "Instagram Insights", text: "Instagram Insights" },
      //       { id: "Youtube Insights", text: "Youtube Insights" },
      //       { id: "LinkedIn Insights", text: "LinkedIn Insights" },
      //       { id: "Twitter Insights", text: "Twitter Insights" },
      //       { id: "Facebook Page Insights", text: "Facebook Page Insights" },
      //     ];
      //   }
      //   return [];
      // },
  
      channelList() {
        var ChannelList;
        switch (this.selectedChannelCategory.text) {
          case "Paid Digital Data":
            ChannelList = [
              { id: "1", text: "Google Ads" },
              { id: "2", text: "Facebook Ads" },
              { id: "3", text: "LinkedIn Ads" },
              { id: "4", text: "Twitter Ads" },
              { id: "5", text: "Display and Video 360" },
              { id: "6", text: "Microsoft Ads" },
              { id: "7", text: "Pinterest" },
              { id: "8", text: "Snapchat" },
            ];
            return ChannelList;
          case "KPI Data":
            ChannelList = [
              { id: "1", text: "Adobe Analytics" },
              { id: "2", text: "Google Analytics" },
              { id: "3", text: "Others" },
            ];
            return ChannelList;
  
          default:
            return [];
        }
      },
      ChannelTypeList() {
        var ChannelTypeList;
        switch (this.selectedChannelCategory.text) {
          case "Paid Digital Data":
            ChannelTypeList = [
              { id: "1", text: "Digital Media Data" },
              { id: "2", text: "Email Marketing Data" },
              { id: "3", text: "SMS Campaigns Data" },
              { id: "4", text: "Push Notifications Data" },
              { id: "5", text: "Affiliate Marketing Data" },
              { id: "6", text: "Channel Partner Data" },
              { id: "7", text: "Influencer Marketing Data" },
              { id: "8", text: "PR Data" },
              { id: "9", text: "Impression Share Data" },
            ];
            return ChannelTypeList;
  
          case "Non Paid Data":
            ChannelTypeList = [
              { id: "1", text: "Google Trends" },
              { id: "2", text: "Organic Social Insights" },
              { id: "3", text: "Keyword Planner Data" },
            ];
            return ChannelTypeList;
  
          case "KPI Data":
            ChannelTypeList = [
              { id: "1", text: "Ecommerce Platform Data" },
              { id: "2", text: "Web Analytics Data" },
              { id: "3", text: "App Analytics Data" },
              { id: "4", text: "Sales Data" },
              { id: "5", text: "All KPI Data" },
            ];
            return ChannelTypeList;
  
          case "Omni Channel USA":
            ChannelTypeList = [
              {
                id: "1",
                text: "TV_USA",
              },
              { id: "2", text: "Print_USA" },
              { id: "3", text: "Radio_USA" },
              { id: "4", text: "Cinema_USA" },
              { id: "5", text: "Outdoor_USA" },
            ];
            return ChannelTypeList;
  
          case "Omni Channel India":
            ChannelTypeList = [
              {
                id: "1",
                text: "TV Data_IND",
              },
              { id: "2", text: "Print_IND" },
              { id: "3", text: "Radio_IND" },
              { id: "4", text: "Cinema_IND" },
              { id: "5", text: "Outdoor_IND" },
              { id: "6", text: "On ground sponsorships_IND" },
              { id: "7", text: "Onground Activities_IND" },
              { id: "8", text: "DTH_IND" },
            ];
            return ChannelTypeList;
  
          case "Brand Data":
            ChannelTypeList = [
              {
                id: "1",
                text: "Distribution Data",
              },
              { id: "2", text: "Promo Details" },
              { id: "3", text: "Marketing Calendar " },
              { id: "4", text: "KPI Definitions" },
            ];
            return ChannelTypeList;
          case "Master Data Format":
            ChannelTypeList = [
              {
                id: "1",
                text: "Master Data Format",}
            ];
            return ChannelTypeList;
  
          default:
            return [];
        }
      },
      showSelectedTypeText() {
        if (this.selectedChannelType.text === undefined) {
          return "";
        } else if(this.selectedChannelType.text !== 'Master Data Format') {
          return `${this.selectedChannelType.text} Format`;
        } else{
          return `${this.selectedChannelType.text}`
        }
      },
    },
    methods: {
      handleContinue() {
        sessionStorage.setItem("activeForm", 2)
        if(sessionStorage.getItem("demoType")){
          if(sessionStorage.getItem("demoType") === 'bfsi'){
            this.$router.push("/demo/bfsi/data-connection-platform/digital")
          }else if(sessionStorage.getItem("demoType") === 'ecomm'){
            this.$router.push("/demo/ecomm/data-connection-platform/digital")
          }
        }
      },
      getSelectedCategory(data) {
        this.selectedCategory = data;
      },
      getSelectedModel(data) {
        this.selectedModel = data;
      },
      getSelectedBrand(data) {
        this.selectedBrand = data;
        this.getModelsName();
      },
      getSelectedChannelCategory(data) {
        this.selectedChannelCategory = data;
      },
      getSelectedChannelType(data) {
        this.selectedChannelType = data;
        if (data.text === "Web Analytics Data") {
          this.isCategoryNameOptional = false;
        } else {
          this.isCategoryNameOptional = true;
        }
      },
      getSelectedOrganization(data) {
        this.selectedOrganization = data;
      },
      onFileChange(event) {
        this.seen = false;
        var fileData = event.target.files[0];
        this.uplodaData = event.target.files[0];
        this.file = fileData.name;
      },
      downloadTemp() {
        var uri = "";
        if (this.selectedChannelCategory.text === undefined) {
          uri = "";
          alert("please select a channel category");
        } else if (this.selectedChannelType.text === "Web Analytics Data") {
          if (this.selectedChannel.text === undefined) {
            alert("please select a channel name for web analytics data");
            uri = "";
          } else {
            uri = `${this.selectedChannelType.text}`;
          }
        } else if (this.selectedChannelType.text === undefined) {
          uri = "";
          alert("please select a channel type");
        } else if (this.selectedChannelCategory.text.length !== 0) {
          uri = `${this.selectedChannelType.text}`;
        }
        if (uri === "") {
          return;
        }
        // if(this.subId === 'aoaukkdwvzjlszefntfq' || this.subId === 'sufpdtslrvuxcwzgziml'){
          dataConnection.downloadFileNewTemplate("Master Format");
        // }else{
        //   dataConnection.downloadFile(this.selectedChannelType.text, uri);
        // }
      },
      clearFileUploader(e) {
        this.$refs.fileInput.value = null;
        this.file = "No file chosen";
        this.seen = true;
        this.uplodaData = "";
        e.preventDefault();
      },
      createPlan() {
        this.uploadButtonLoader = "loader";
        this.uploadButtonText = "Uploading...";
        this.errorMessage = "";
        let formData = new FormData();
        formData.append("file", this.uplodaData);
        this.selectedChannel.text === "Others"
          ? (this.selectedChannel.text = "")
          : this.selectedChannel.text;
        this.selectedChannel.text === undefined
          ? (this.selectedChannel.text = "")
          : this.selectedChannel.text;
        dataConnection
          .uploadFile(
            formData,
            this.$removeSpecialChar(sessionStorage.getItem("clientId")),
            sessionStorage.getItem("subId"),
            this.selectedBrand.id,
            this.selectedChannelCategory.text,
            this.selectedChannelType.text,
            this.dataStreamName,
            this.selectedChannel.text,
            this.selectedModel.text
          )
          .then((res) => {
            if (res.status === "FAILED") {
              this.errorMessage = res.failureMessage;
            } else if (res.status === "SUCCESS") {
              this.toastData.show = true;
              this.toastData.type = "success";
              this.toastData.message =
                "Yay, your datastream has been created successfully";
            }
            this.uploadButtonText = "Upload";
            this.uploadButtonLoader = "normal";
          })
          .catch(() => {
            this.uploadButtonText = "Upload";
            this.uploadButtonLoader = "normal";
            this.errorMessage = "Somwthing went wrong please try after sometime";
          });
        // this.$newRouterPush("/upload-data/kpi");
        // this.$refs.solutionModal.open();
      },
      clearToast() {
        this.toastData = {
          show: false,
          type: "",
          message: "",
        };
      },
      doneProgress() {
        this.$newRouterPush("/upload-data/kpi");
      },
      getAllBrand() {
        if (this.subId === "aoaukkdwvzjlszefntfq" || this.subId === "mqlrludacmqjvonlifei" || this.subId === 'bmrfjilcasneiwxynugg') {
  
          let brandList = []
          dataConnection
            .getBrands(sessionStorage.getItem("subId"))
            .then((res) => {
              brandList = [];
              // res = res.data
              for (var i = 0; i < res.response.content.length; i++) {
                var brandData = {
                  text: `${res.response.content[i].brandName}`,
                  id: `${res.response.content[i].id}`,
                };
                brandList.push(brandData);
              }
              let selectedSessionBrand = JSON.parse(
                  sessionStorage.getItem("selectedBrandKPI")
                );
                console.log(selectedSessionBrand.text, brandList)
                let brandId = brandList.filter((r)=>{
                  if(r.text === selectedSessionBrand.text){
                    return r
                  }
                })
                console.log(brandId)
                this.selectedBrand = {id: brandId[0].id, text:brandId[0].text }
                this.getSelectedBrand(this.selectedBrand)
            })
            .catch((e) => {
              console.log(e);
            });
        } else {
          dataConnection
            .getBrands(sessionStorage.getItem("subId"))
            .then((res) => {
              this.brandList = [];
              // res = res.data
              for (var i = 0; i < res.response.content.length; i++) {
                var brandData = {
                  text: `${res.response.content[i].brandName}`,
                  id: `${res.response.content[i].id}`,
                };
                this.brandList.push(brandData);
              }
            })
            .catch((e) => {
              console.log(e);
            });
        }
      },
      getModelsName() {
        dataVerification
          .getBrandDetails(
            this.selectedBrand.text,
            sessionStorage.getItem("subId")
          )
          .then((res) => {
            console.log(res);
            this.allModelsList = [];
            for (let i = 0; i < res?.model?.length; i++) {
              this.allModelsList.push({ id: res.model[i], text: res.model[i] });
            }
            // this.selectedModel = this.allModelsList[0]
            // this.getChannelTypes();
            // this.getStatusForClient();
          })
          .catch((err) => {
            console.log(err);
          });
      },
    },
    created() {
      this.subId = sessionStorage.getItem("subId");
      this.getAllBrand();
      if(sessionStorage.getItem('clientId') === "genericdemo@datapoem.com"){
        this.OrganizationList = [{id:1, text: "Demo"}]
        this.selectedOrganization = {id:1, text: "Demo"}
      }
      if(this.subId === 'bmrfjilcasneiwxynugg'){
        this.OrganizationList = [{ id: "1", text: 'Kenvue' }]
        this.selectedOrganization = { id: "1", text: 'Kenvue' }
  
      }
    },
  };
  </script>
  <style scoped>
  :-ms-input-placeholder {
    color: #9aafd4;
  }
  
  ::-ms-input-placeholder {
    color: #9aafd4;
  }
  
  input:focus {
    box-shadow: none;
    border: 1px solid #80bdff;
  }
  
  input,
  input:focus {
    height: 60px;
    object-fit: contain;
    color: #9aafd4;
    background-color: #f2f5fa;
    font-family: ProximaNovaRegular;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    box-shadow: none;
    border: 0;
  }
  
  .changeColor .disableButton {
    color: #fff !important;
  }
  
  .action {
    color: #000;
  }
  
  .container {
    height: 100%;
  }
  
  .card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-color: #fff;
    margin-bottom: 8px;
  }
  
  .step-indicator .stepactive::after {
    background: #050505;
  }
  
  .tab-panel.showConnectors {
    display: block;
  }
  
  .tab-panel {
    display: none;
  }
  
  .card {
    display: block;
  }
  
  .Use-product-data-to {
    height: 35px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: left;
    color: #c9ced5;
  }
  
  .form-file-input {
    margin: 0px;
    padding: 15px;
    width: 100%;
    outline: none;
    height: 60px;
    border-radius: 5px;
    font-size: 20px;
    color: #9aafd4;
    border: 0;
    background-color: #eff5ff;
  }
  
  .choose-file-btn {
    width: 115px;
    position: absolute;
    top: 0;
    border-radius: 5px;
    right: 0px;
    z-index: 2;
    border: none;
    top: 7px;
    height: 44px;
    cursor: pointer;
    color: #222a37;
    background-color: #eff5ff;
    transform: translateX(-39px);
    border: 2px solid #050505;
    font-size: 16px;
  }
  
  label.file-label {
    width: 115px;
    position: absolute;
    top: 0;
    border-radius: 5px;
    right: 0px;
    border: none;
    top: 7px;
    height: 44px;
    cursor: pointer;
    color: #222a37;
    background-color: #eff5ff;
    transform: translateX(-39px);
    border: 2px solid #050505;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: ProximaNovaRegular;
  }
  
  .remove-label {
    cursor: pointer;
    color: #ff5a5a;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: ProximaNovaRegular;
  }
  
  #file-chosen {
    margin: 0px;
    width: 100%;
    outline: none;
    height: 60px;
    border-radius: 5px;
    font-size: 15px;
    color: #9aafd4;
    border: 0;
    background-color: #eff5ff;
    margin-left: 0.3rem;
    font-family: sans-serif;
    display: flex;
    align-items: center;
    padding-left: 10px;
  }
  
  .input-types {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }
  
  span#file-chosen {
    color: #222a37;
    font-family: ProximaNovaBold;
    font-size: 16px;
  }
  
  span.d-flex.ProximaNovaSemiBold.p-3 {
    font-size: 18px !important;
    font-family: ProximaNovaRegular !important;
    font-weight: normal;
  }
  
  @media screen and (max-width: 640px) {
    .row.product-data-section {
      flex-direction: column;
      padding-bottom: 15px;
    }
  
    .input-types {
      position: relative;
      width: 75%;
      padding-right: 15px;
      padding-left: 15px;
    }
  
    .container-fluid {
      padding-left: 0 !important;
    }
  
    .col-md-12.col-lg-12.product-data-section {
      width: 100%;
      margin-left: 18%;
    }
  }
  
  @media screen and (max-width: 768px) {
    .row.product-data-section {
      flex-direction: column;
      padding-bottom: 15px;
    }
  
    img.img.img-responsive {
      margin: auto;
    }
  
    .input-types {
      position: relative;
      width: 75%;
      padding-right: 15px;
      padding-left: 15px;
    }
  
    .col-md-12.col-lg-12.product-data-section {
      width: 100%;
      margin: 0% !important;
    }
  }
  
  @media screen and (max-width: 900px) {
    .input-types {
      position: relative;
      width: 100%;
      padding-right: 15px;
      padding-left: 15px;
    }
  
    .col-md-12.col-lg-12.product-data-section {
      width: 100%;
      margin: 0% !important;
    }
  }
  
  @media screen and (max-width: 1024px) {
    #file-chosen {
      font-size: 15px !important;
    }
  
    .col-md-12.col-lg-12.product-data-section {
      width: 100%;
      margin: 0% !important;
    }
  }
  
  .download-channel-data {
    width: 100%;
    height: 20px;
    right: 0px;
    font-family: ProximaNovaRegular;
    font-size: 18px;
    font-weight: normal;
    position: relative;
    margin-top: 0px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #222a37;
    text-decoration: underline;
    /* color: blueviolet; */
  }
  
  .download-channel-data > a {
    cursor: pointer;
  }
  </style>
  